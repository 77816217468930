import styled from 'styled-components'

export const Container = styled.div`
    flex:1;
    height:100vh;
`;

export const Card = styled.div`
    width:500px;
    background-color:white;
    border-radius:3px;
    box-shadow: 0 10px 30px #7878785e;
    margin:160px auto;

    .card-body {
        padding:40px;

        h4 {
            margin:0;
            font-size:40px;
            color:#464646;
        }
        p {
            color:#464646;
            margin:0;
            margin-top:10px;
            font-size:18px;
        }
    }
`;