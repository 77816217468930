import * as C from './styles'

import PageTemplate from '../../Components/PageTemplate';


const NotFound = () => {


    return (
        <C.Container>
            <PageTemplate bgIdenti='bg'>
                <C.Card>
                    <div className='card-body'>
                        <h4>
                            Erro 404
                        </h4>
                        <p>Página não encontrada.</p>
                    </div>
                </C.Card>
            </PageTemplate>
        </C.Container>
    )
}

export default NotFound;