import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom"
import { Suspense, lazy } from 'react'
import router from 'react-named-routes'

import NotFound from "./Pages/NotFound"

const Login = lazy(() => import('./Pages/Signup/Login'));
const Signup = lazy(() => import('./Pages/Signup'));
const Step1 = lazy(() => import('./Pages/Signup/Step1'));
const Step2 = lazy(() => import('./Pages/Signup/Step2'));
const Step3 = lazy(() => import('./Pages/Signup/Step3'));
const Step4 = lazy(() => import('./Pages/Signup/Step4'));
const Step5 = lazy(() => import('./Pages/Signup/Step5'));
const Details = lazy(() => import('./Pages/Details'));

router.registerRoutes('', {
    signup: { path: '/signup', element: <Signup />},
    step1: { path: '/signup/1', element: <Step1 />},
    step2: { path: '/signup/2', element: <Step2 />},
    step3: { path: '/signup/3', element: <Step3 />},
    step4: { path: '/signup/4', element: <Step4 />},
    step5: { path: '/signup/5', element: <Step5 />},
    details: { path: '/details', element: <Details />}
})

export default function AppRoute() {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Carregando...</div>}>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route {...router.getRoute('signup')} >
                        <Route {...router.getRoute('step1')} />
                        <Route {...router.getRoute('step2')} />
                        <Route {...router.getRoute('step3')} />
                        <Route {...router.getRoute('step4')} />
                        <Route {...router.getRoute('step5')} />
                    </Route>
                    <Route {...router.getRoute('details')} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}