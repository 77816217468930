import { ReactNode } from "react"
import * as C from './styles'
import SatcLogo from '../../assets/img/Logo_satc.svg'
import SatcLogo2 from '../../assets/img/Logo_satc2.png'


type Props = {
    children: ReactNode | ReactNode[],
    bgIdenti: string
}
export default function PageTemplate(props: Props) {

    return (
        <>
        <C.Container>
            <C.Title bg={props.bgIdenti} >
                <h3>Seja bem-vindo(a) ao</h3>
                <h1>Unisatc PRO</h1>
            </C.Title>
                {props.children}
            <C.Footer bg={props.bgIdenti} >
                {props.bgIdenti === 'unisatcpro' && <img src={SatcLogo} alt='' />}
                {props.bgIdenti === 'bg' && <img src={SatcLogo2} alt='' />}
                <span>TI Satc</span>
            </C.Footer>
        </C.Container>
        <C.Bg bg={props.bgIdenti}></C.Bg>
        </>
    )
}